import React from 'react';
import Card, { CardContent } from '../../common/Card';
import ClosedAccountIcon from '../style/icons/closed_account.svg';
import styles from './closeaccount.module.scss';

const CloseAccount = () => {
  return (
    <Card title="Account is Closed!">
      <>
        <CardContent>
          <img src={ClosedAccountIcon} alt="Account closed icon" className={styles.closedAccountIcon} />
        </CardContent>
        <CardContent>
          <p className={styles.content}>
            Thank you for being a part of our journey. Hope we serve you the best
            with our services.
          </p>
        </CardContent>
      </>
    </Card>
  );
};

export default CloseAccount;
